import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactPage = ({ data }) => (
  <Layout>
    <SEO
      title="Contact"
      keywords={[
        `Logopediepraktijk`,
        `Mireille Peters`,
        `Zeewolde`,
        `Harderwijk`,
        `Ermelo`,
      ]}
    />
    <div className="container">
      <h2 className="contact__header">Contact</h2>
      {/* form container */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <form
              action="https://formspree.io/info@logopedie-zeewolde.nl"
              method="POST"
              className="contact-form"
            >
              <div className="container">
                {/* section one */}
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="contact_person">
                        Contactpersoon <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="contact_person"
                        name="contactpersoon"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="client_name">
                        Naam Client <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="client_name"
                        name="client"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="date_of_birth">
                        Geboortedatum client <span className="required">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="date_of_birth"
                        name="geboorte-datum"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>
                {/* section 2 */}
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="address">
                        Straat en huisnummer <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder=""
                        required
                        name="adres"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="postalcode">
                        Postcode <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="postalcode"
                        placeholder=""
                        name="postcode"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="city">
                        Woonplaats <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder=""
                        name="plaats"
                      />
                    </div>
                  </div>
                </div>
                {/* section three */}
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="phone_number">
                        Telefoonnummer <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone_number"
                        placeholder=""
                        required
                        name="telefoonnummer"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="email">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emil"
                        placeholder=""
                        name="email"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="doctor">
                        Huisarts <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="doctor"
                        placeholder=""
                        name="huisarts"
                      />
                    </div>
                  </div>
                </div>
                {/* section four */}
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="referrer">
                        Verwijzer <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="referrer"
                        placeholder=""
                        required
                        name="verwijzer"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="availabilty">
                        Beschikbaarheid (dagen/dagdelen) <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="availabilty"
                        placeholder=""
                        name="beschikbaarheid"
                      />
                    </div>
                  </div>
                                    <div className="col-12 col-md-4">
                    <div className="form-group">
                      <label for="daycare">
                         School/PSZ/KDV/n.v.t <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="daycare"
                        placeholder=""
                        name="opvang"
                      />
                    </div>
                  </div>
                </div>
                {/* section five */}
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label for="message">
                        Bericht / Hulpvraag <span className="required">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        id="mesage"
                        rows="5"
                        name="bericht"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="text-right col-12">
                    <button type="submit" className="btn">
                      Verzenden
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* locations container */}
      <div className=" container">
        <div className="row">
          {data.locaties.edges.map(locatie => (
            <div
              className="contact__location col-12 col-md-4"
              key={locatie.node.id}
            >
              <h5 className="contact__location__header">
                {locatie.node.locatie}
              </h5>
              <div className="contact__location__address">
                {locatie.node.adres} <br />
                {locatie.node.postcode} <br />
                {locatie.node.plaats} <br />
              </div>
              <div className="contact__location__contact">
                {locatie.node.telefoonnummer} <br />
                {locatie.node.email} <br />
              </div>
              <div className="contact__location__open">
                {locatie.node.openingsDagen} <br />
                {locatie.node.openingstijden}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    locaties: allContentfulLocaties(sort: { fields: [locatie], order: DESC }) {
      edges {
        node {
          id
          locatie
          adres
          postcode
          plaats
          email
          telefoonnummer
          openingsDagen
          openingstijden
        }
      }
    }
  }
`
export default ContactPage
